import { ledgerConstants } from "../../_constants";
import { getLedger} from "../../_services";
import {toast} from 'react-toastify';

export const getClientLedger = (callback) => {
  return (dispatch) => {
    dispatch({ type: ledgerConstants.GET_CLIENT_LEDGER_REQ, data: {} });
    getLedger().then((response) => {
      callback(response.data);
      dispatch({ type: ledgerConstants.GET_CLIENT_LEDGER_SUCC, data: response.data });
    }).catch((error) => {
      callback(false);
      dispatch({ type: ledgerConstants.FAILED_RESPONSE, data: error });
    });
  };
};